import React, { useEffect, useState } from "react";
import ContactSection1 from "./contact-banner";
import ContactSection2 from "./contact-panel1";
import { getContact } from "../../actions/mainActions";
import { useDispatch, useSelector } from "react-redux";
import { BannerImage } from "../Utilities/banner-image";

function ContactUs() {
  const dispatch = useDispatch();
  const [sectionData, setSectionData] = useState();

  useEffect(() => {
    dispatch(getContact());
  }, [dispatch]);

  let data1 = useSelector((mainReducer) => mainReducer.mainReducer.contact);

  useEffect(() => {
    data1.map((data) =>
      setSectionData({
        contactUs: data.acf["section1-contact"],
        locations: Object.values(data.acf["contact_tabs"]),
      })
    );
  }, [data1]);
  if (sectionData != null) {
    var singleLocation = [],
      i = 0,
      n = sectionData.locations.length;
    while (i < n) {
      singleLocation.push(sectionData.locations.slice(i, (i += 3)));
    }
  }
  return (
    <div className="section-div">
      {sectionData != null ? (
        <>
          <section
            className="bodyContainer"
            style={BannerImage(sectionData.contactUs["contact-image"].url)}
          >
            <ContactSection1
              title={sectionData.contactUs["contact-title"]}
              desc={sectionData.contactUs["contact-text"]}
            />
          </section>

          <section className="bodyContainer">
            <ContactSection2 location={singleLocation} />
          </section>
        </>
      ) : null}
    </div>
  );
}

export default ContactUs;

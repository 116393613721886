import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function ContactSection1(props) {
  return (
    <Container>
      <Row className="w-100 h-100 lightTheme">
        <Col xs={12} md={8}>
          <Row className="d-flex justify-content-center section-DescText">
            <h1 className="sectionTitle">
              <strong
                dangerouslySetInnerHTML={{
                  __html: props.title,
                }}
              />
            </h1>
            <p
              className="mt-3 sectionContent"
              dangerouslySetInnerHTML={{
                __html: props.desc,
              }}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactSection1;

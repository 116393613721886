import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Button, FloatingLabel, Form, Spinner } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { getStorage, ref, uploadBytes, listAll } from "firebase/storage";

function Apply(props) {
  const [fullName, setFullName] = useState({});
  const [email, setEmail] = useState({});
  const [contact, setContact] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [selectedResumeFile, setSelectedResumeFile] = useState(null);
  const [fileName2, setFileName2] = useState("");
  const [applySuccess, setApplySuccess] = useState(false);
  const [load, setLoad] = useState(false);
  const [fileInBucket] = useState([]);
  var parser = new DOMParser();
  const subject =
    "Job Application: " +
    parser.parseFromString(props.jobTitle, "text/html").body.innerHTML;

  //handle CV Input, handle duplicates in the storage and format spaces file name
  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);

    if (e.target.files[0] != null) {
      // setFileName(e.target.files[0].name);
      var fileNameCheck = /\s/g.test(e.target.files[0].name);
      if (fileNameCheck) {
        setFileName(e.target.files[0].name.replace(/\s/g, ""));
      } else {
        setFileName(e.target.files[0].name);
      }
    }
  };

  const handleResumeInput = (e) => {
    setSelectedResumeFile(e.target.files[0]);
    if (e.target.files[0] != null) {
      // setFileName2(e.target.files[0].name);
      var fileNameCheck2 = /\s/g.test(e.target.files[0].name);
      if (fileNameCheck2) {
        setFileName2(e.target.files[0].name.replace(/\s/g, ""));
      } else {
        setFileName2(e.target.files[0].name);
      }
    }
  };

  // initialise firebase
  const storage = getStorage();
  const storageRef = ref(storage, fileName);
  const storageRef2 = ref(storage, fileName2);
  const listRef = ref(storage);

  // fetch firebase stroage contents
  useEffect(() => {
    listAll(listRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          fileInBucket.push(itemRef._location.path_);
        });
      })
      .catch((error) => {
        // catch error
      });
  }, []);

  // disable submit if cv is not uploaded or while form is submitting
  var disabledBtn = true;
  if (
    selectedFile == null ||
    fullName == null ||
    contact == null ||
    email == null ||
    load
  ) {
    disabledBtn = true;
  } else {
    disabledBtn = false;
  }

  // if dublicate file name is found in storage prefix unique code to file name
  if (fileInBucket.includes(fileName)) {
    let uniqueNumb = Math.ceil(Math.random() * 1000);
    setFileName(uniqueNumb.toString() + fileName);
  }

  if (fileInBucket.includes(fileName2)) {
    let uniqueNumb = Math.ceil(Math.random() * 1000);
    setFileName2(uniqueNumb.toString() + fileName2);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // upload cv to firebase
    uploadBytes(storageRef, selectedFile);

    // upload coverLetter to firebase if uploaded by user
    if (selectedResumeFile != null) {
      uploadBytes(storageRef2, selectedResumeFile);
    }

    // firebase config
    var resumeUrl = "Not Provided";
    if (selectedResumeFile != null) {
      resumeUrl = `https://firebasestorage.googleapis.com/v0/b/groupsite-bb1c0.appspot.com/o/${fileName2}?alt=media`;
    }

    const formData = {
      name: fullName,
      email: email,
      contact: `+${contact}`,
      subject: subject,
      to_Email: props.email,
      cvUrl: `https://firebasestorage.googleapis.com/v0/b/groupsite-bb1c0.appspot.com/o/${fileName}?alt=media`,
      resumeUrl: resumeUrl,
    };
    // emailjs config
    setLoad(true);
    emailjs
      .send(
        "service_7zo0h2o",
        "template_ooah2ro",
        formData,
        "user_zwkqW7MSZelK98oKGLPsv"
      )
      .then(
        (result) => {
          setApplySuccess(true);
          setLoad(false);
        },
        (error) => {
          setApplySuccess(false);
          setLoad(false);
        }
      );
  };

  return (
    <>
      <div className="darkTheme p-3 jobCard">
        {applySuccess ? (
          <strong className="text-muted">
            You applicaiton was submitted successfully.
          </strong>
        ) : (
          <>
            <small className="text-muted">
              Fields marked with <strong style={{ color: "red" }}>*</strong> are
              required
            </small>
            <Form
              className="p-4"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <Form.Group className="mb-3" controlId="formName">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Full Name"
                  className="mb-3 required"
                >
                  <Form.Control
                    required
                    type="text"
                    placeholder="Full Name"
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <FloatingLabel
                  controlId="floatingInput2"
                  label="Email"
                  className="mb-3 required"
                >
                  <Form.Control
                    required
                    type="email"
                    placeholder="name@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FloatingLabel>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formContact">
                <Form.Label className="required">Contact:</Form.Label>
                <PhoneInput
                  autoFormat={true}
                  country="au"
                  preferredCountries={["au", "gb"]}
                  value={contact}
                  onChange={setContact}
                  placeholder="Phone"
                  enableSearch={true}
                  inputProps={{
                    name: "Phone",
                    required: true,
                    autoFocus: true,
                  }}
                />
              </Form.Group>

              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label className="required">Upload CV:</Form.Label>
                <Form.Control
                  type="file"
                  name="cv"
                  onChange={handleFileInput}
                  accept=".pdf, .doc, .docx,"
                  required
                />
              </Form.Group>

              <Form.Group controlId="formFile2" className="mb-3">
                <Form.Label>Upload Cover Letter:</Form.Label>
                <Form.Control
                  type="file"
                  name="coverLetter"
                  onChange={handleResumeInput}
                  accept=".pdf, .doc, .docx,"
                />
              </Form.Group>
              <div className="d-flex justify-content-center align-items-center mainBtn">
                <Button
                  type="submit"
                  className="mb-2 mt-3 w-100"
                  disabled={disabledBtn}
                >
                  {(() => {
                    if (load) {
                      return <Spinner size="sm" animation="border" />;
                    } else if (!load && applySuccess) {
                      return <>Successful</>;
                    } else {
                      return <>Submit</>;
                    }
                  })()}
                </Button>
              </div>
            </Form>
          </>
        )}
      </div>
    </>
  );
}

export default Apply;

import React, { useEffect, useState } from "react";
import AboutSection1 from "./about-panel1";
import AboutSection2 from "./about-banner";
import { getAbout } from "../../actions/mainActions";
import { useDispatch, useSelector } from "react-redux";
import { BannerImage } from "../Utilities/banner-image";

function About() {
  const dispatch = useDispatch();
  const [sectionData, setSectionData] = useState();

  useEffect(() => {
    dispatch(getAbout());
  }, [dispatch]);

  let data1 = useSelector((mainReducer) => mainReducer.mainReducer.about);

  useEffect(() => {
    data1.map((data) =>
      setSectionData({
        about: data.acf["section1-about"],
        aboutValues: data.acf["section2-values"],
        aboutAwards: data.acf["section2-awards"],
        aboutCards: Object.values(data.acf["about_info_cards"]),
      })
    );
  }, [data1]);

  return (
    <div className="section-div">
      {sectionData != null ? (
        <>
          <section
            className="bodyContainer"
            style={BannerImage(sectionData.about["about-image"].url)}
          >
            <AboutSection1
              title={sectionData.about["about-title"]}
              desc={sectionData.about["about-text"]}
            />
          </section>

          <section className="bodyContainer">
            <AboutSection2
              valuesTitle={sectionData.aboutValues["values-title"]}
              valuesText={sectionData.aboutValues["values-text"]}
              awardsTitle={sectionData.aboutAwards["awards-title"]}
              awardsText={sectionData.aboutAwards["awards-text"]}
              awardsImage={sectionData.aboutAwards["awards-image"]}
              investmentTrend={sectionData.aboutAwards["investment-trend"]}
              cards={sectionData.aboutCards}
            />
          </section>
        </>
      ) : null}
    </div>
  );
}

export default About;

import React, { useEffect, useState } from "react";
import { Row, Col, Accordion, Card, Badge, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSingleJob, getJobs } from "../../actions/mainActions";
import Apply from "./application-form";
import { DetailsLoader } from "../SiteElements/loading";
import { useParams, useNavigate } from "react-router-dom";
import Share from "../SiteElements/share";

function JobDetails() {
  const dispatch = useDispatch();
  const [sectionData, setSectionData] = useState();
  let { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(getSingleJob(id, navigate));
  }, [dispatch, id, navigate]);

  useEffect(() => {
    dispatch(getJobs());
  }, [dispatch]);

  let data1 = useSelector((mainReducer) => mainReducer.mainReducer.wpJobData);
  let data2 = useSelector((mainReducer) => mainReducer.mainReducer.wpData);

  useEffect(() => {
    data2.map((data) =>
      setSectionData({
        sectionTitle: data.title.rendered,
        sectionText: data.acf["benefits_text"],
        benefits: data.acf["benefits"],
        link: data.acf["learn_more_link"],
      })
    );
  }, [data2]);

  // handle undefined/null values
  let jobDesc,
    jobTitle,
    email,
    jobUlrs,
    dept,
    jobQualification,
    jobOtherDetials = "";

  if (data1.content != null) {
    jobTitle = data1.title.rendered;
    email = data1.meta._application;
    jobUlrs = data1.acf["jobOtherUrls"];
    jobDesc = data1.acf["jobDescription"];
    jobQualification = data1.acf["jobRequiredQualification"];
    jobOtherDetials = data1.acf["jobOtherDetails"];
    dept = data1.acf["jobdepartment"];
  }

  let url = jobUlrs;
  if (url != null) {
    if (!url.includes("https://") && url !== "") {
      url = "https://" + jobUlrs;
    } else {
      url = jobUlrs;
    }
  }

  // filter out job type
  let jobT;
  if (data1["job-types"] != null) {
    for (const value of data1["job-types"].values()) {
      if (value === 2) {
        jobT = "Full Time";
      } else if (value === 3) {
        jobT = "Part Time";
      } else if (value === 4) {
        jobT = "Temporary";
      } else if (value === 6) {
        jobT = "Internship";
      }
    }
  }

  let date = data1.date;
  const jobPostDate = new Date(date);
  const today = new Date();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let actaulDate =
    jobPostDate.getDate() + " " + monthNames[jobPostDate.getMonth()];

  let currentDate = today.getDate() + " " + monthNames[today.getMonth()];

  let postDate = actaulDate;

  if (currentDate === actaulDate) {
    postDate = "Today";
  } else {
    postDate = actaulDate;
  }

  return (
    <>
      <Container>
        {data1.content != null ? (
          <>
            <div className="mt-5 responsive-center content-Center">
              <span
                className="pageSub-Headers"
                dangerouslySetInnerHTML={{
                  __html: jobTitle,
                }}
              />
              <div className="pageSub-Nav content-Center">
                <a
                  className="sectionContent-Secondary"
                  variant="primary"
                  href="/careers/positions"
                >
                  {" "}
                  &lt; Back to All Jobs
                </a>
              </div>
              <hr className="secondary-divider" />
            </div>
            <Row className="row justify-content-center">
              <Col sm={8}>
                <div className=" p-2 jobDetailPage">
                  <header>
                    <Share />
                    <Badge bg="none" className="card-badge mb-2">
                      {dept}
                    </Badge>

                    <p className="extra-info-small">
                      {jobT} - {data1._job_location}
                    </p>
                  </header>
                  <div className="sectionContent-Secondary">
                    <small className="fw-bold">Posted {postDate}</small>

                    <div className="mt-4">
                      <strong>Details:</strong>

                      <p
                        className="text-muted"
                        dangerouslySetInnerHTML={{
                          __html: jobDesc,
                        }}
                      />
                    </div>
                    <div className="mt-4">
                      <strong>Required Qualification:</strong>

                      <p
                        className="text-muted"
                        dangerouslySetInnerHTML={{
                          __html: jobQualification,
                        }}
                      />
                    </div>
                    <div className="mt-4">
                      <strong>Other Details:</strong>

                      <p
                        className="text-muted"
                        dangerouslySetInnerHTML={{
                          __html: jobOtherDetials,
                        }}
                      />
                    </div>
                    <hr />
                  </div>
                  <Row className="sectionContent-Secondary">
                    <Col>
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            Apply With GO Markets
                          </Accordion.Header>
                          <Accordion.Body>
                            <Apply
                              email={email}
                              companyName={data1._company_name}
                              jobTitle={jobTitle}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        {url !== "" ? (
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>Apply With Seek</Accordion.Header>
                            <Accordion.Body>
                              {url !== "" ? (
                                <a href={url} target="_blank" rel="noopener">
                                  Visit SEEK.Com
                                </a>
                              ) : (
                                ""
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        ) : (
                          ""
                        )}
                      </Accordion>
                    </Col>
                  </Row>
                  <hr />
                </div>
              </Col>
              <Col sm={3}>
                <Card>
                  {sectionData != null ? (
                    <Card.Body>
                      <div>
                        <Card.Title>
                          <span className="content-Headers">
                            {sectionData.sectionTitle}
                          </span>
                        </Card.Title>

                        <Card.Text
                          className="sectionContent-Secondary"
                          dangerouslySetInnerHTML={{
                            __html: sectionData.sectionText,
                          }}
                        />
                        <div
                          className="sectionContent-Secondary"
                          dangerouslySetInnerHTML={{
                            __html: sectionData.benefits,
                          }}
                        />
                      </div>
                      <div style={{ marginTop: "117px" }}>
                        <Card.Link
                          href={sectionData.link.url}
                          target="_blank"
                          rel="noopener"
                          className="text-decoration-none"
                        >
                          {sectionData.link.title} &gt;
                        </Card.Link>
                      </div>
                    </Card.Body>
                  ) : (
                    ""
                  )}
                </Card>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <DetailsLoader />
          </>
        )}
      </Container>
    </>
  );
}

export default JobDetails;

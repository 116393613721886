import React from "react";
import { Row, Container } from "react-bootstrap";

function ContentSection(props) {
  /* useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://gomarkets.bamboohr.com/jobs/embed2.php?version=encodeURIComponent(1.0.0)";
    script.async = true;
    document.body.appendChild(script);
  });  */
  return (
    <div>
      <Container>
        {props.data.map((data, index) => (
          <Row key={index} className="d-flex justify-content-center">
            <div className="mb-5">
              <h1 className="p-4">
                <strong
                  className="m-0 sectionTitle"
                  dangerouslySetInnerHTML={{
                    __html: data.title,
                  }}
                />
              </h1>
              <div
                className="sectionContent-Primary d-flex justify-content-center"
                dangerouslySetInnerHTML={{
                  __html: data.content,
                }}
              />

              <img
                width="50%"
                height="auto"
                alt={data.image.alt}
                style={{ marginTop: "54px" }}
                src={data.image.url}
              />
            </div>
          </Row>
        ))}
      </Container>
    </div>
  );
}

export default ContentSection;

import React, { useState } from "react";
import { Col, Row, Button, Table, Container, Card } from "react-bootstrap";

import JobCard from "../Job/job-item";
import MapChart from "../map-chart";
import { Tooltip } from "react-tooltip";

function CareersSection2(props) {
  const [content, setContent] = useState("");

  const extractData = (obj) => {
    let content = document.createElement("div");
    content.innerHTML = obj;
    let description = [...content.querySelectorAll("figure")].map(
      (x) => x.innerHTML
    );

    return description;
  };

  return (
    <Container>
      <Row className="w-100 darkTheme mx-0">
        <Col>
          <Row className="d-flex justify-content-center">
            <strong
              className="pageSub-Headers m-0"
              dangerouslySetInnerHTML={{
                __html: props.whyGoTitle,
              }}
            />

            <h2
              className="sectionContent-Primary justify-content-center p-5"
              dangerouslySetInnerHTML={{
                __html: props.whyGoDesc,
              }}
            />
          </Row>

          <Row className="mt-4">
            <div className=" mb-5">
              <h1>
                <strong className="pageSub-Headers m-0">Business Areas</strong>
              </h1>
            </div>
            <div className="sectionTwo-businessAreas">
              <Table>
                <tbody>
                  <tr>
                    {props.businessTable.map((items, index) => (
                      <td key={index}>
                        <strong className="sectionContent-Secondary mx-1">
                          {items.caption}
                        </strong>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {props.businessTable2.map((items, index) => (
                      <td key={index}>
                        <strong className="sectionContent-Secondary mx-1">
                          {items.caption}
                        </strong>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>
            </div>
          </Row>

          <Row className="mt-5  d-flex justify-content-center">
            {[...extractData(props.whyGoCol1)].map((index, item) => (
              <Col sm={4} key={index} lg className="mx-2 mt-1">
                <Card className="whyGoMarkets-card" style={{ height: "130px" }}>
                  <Card.Body className="text-start">
                    <Card.Text
                      dangerouslySetInnerHTML={{
                        __html: extractData(props.whyGoCol1)[item],
                      }}
                    />
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <Row className="d-flex justify-content-center">
            {[...extractData(props.whyGoCol2)].map((index, item) => (
              <Col sm={4} key={index} lg className="mx-2 mt-1">
                <Card className="whyGoMarkets-card" style={{ height: "130px" }}>
                  <Card.Body className="text-start">
                    <Card.Text
                      dangerouslySetInnerHTML={{
                        __html: extractData(props.whyGoCol2)[item],
                      }}
                    />
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>

          <Row
            className="d-flex justify-content-center"
            style={{ marginTop: "10rem" }}
          >
            <h1>
              <strong
                className="pageSub-Headers m-0"
                dangerouslySetInnerHTML={{
                  __html: props.location,
                }}
              />
            </h1>
            <div>
              <h6
                className="sectionContent-Primary d-flex justify-content-center p-5"
                dangerouslySetInnerHTML={{
                  __html: props.locationDesc,
                }}
              />
            </div>
          </Row>
          <MapChart setTooltipContent={setContent} />
          <Tooltip>{content}</Tooltip>

          {props.jobData.length > 0 ? (
            <Row style={{ marginTop: "6rem" }}>
              <div className=" mb-5">
                <h1>
                  <strong className="pageSub-Headers m-0">Job Vacancies</strong>
                </h1>
              </div>
              <hr />
              {props.jobData.length > 5
                ? props.jobData
                    .slice(Math.max(props.jobData.length - 5, 0))
                    .map((items) => (
                      <Row
                        key={items.id}
                        className="sectionTwo-recentPosts-cards"
                      >
                        <JobCard
                          id={items.id}
                          slug={items.slug}
                          jobTitle={items.title.rendered}
                          jobDesc={items.acf["jobDescription"]}
                          jobQualification={
                            items.acf["jobRequiredQualification"]
                          }
                          jobOtherDetials={items.acf["jobOtherDetails"]}
                          datePosted={items.date}
                          location={items.meta._job_location}
                          jobType={items["job-types"]}
                          email={items.meta._application}
                          jobUrl={items.acf["jobOtherUrls"]}
                          logoId={items.featured_media}
                          dept={items.acf["jobdepartment"]}
                        />
                      </Row>
                    ))
                : props.jobData.map((items) => (
                    <Row
                      key={items.id}
                      className="sectionTwo-recentPosts-cards"
                    >
                      <JobCard
                        id={items.id}
                        slug={items.slug}
                        jobTitle={items.title.rendered}
                        jobDesc={items.acf["jobDescription"]}
                        jobQualification={items.acf["jobRequiredQualification"]}
                        jobOtherDetials={items.acf["jobOtherDetails"]}
                        companyName={items._company_name}
                        datePosted={items.date}
                        location={items.meta._job_location}
                        jobType={items["job-types"]}
                        email={items.meta._application}
                        jobUrl={items.acf["jobOtherUrls"]}
                        logoId={items.featured_media}
                        dept={items.acf["jobdepartment"]}
                      />
                    </Row>
                  ))}
              <hr />
              <div className="mt-5 mainBtn">
                <Button href="/careers/positions">See all jobs</Button>
              </div>
            </Row>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default CareersSection2;

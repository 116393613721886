import "./App.scss";
import "@fontsource/roboto";
import "@fontsource/mulish";
import "@fontsource/poppins";
import JobBoard from "./components/Job/job-board";
import Main from "./components/Home/home";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import HeaderNav from "./components/SiteElements/header-nav";
import JobDetails from "./components/Job/single-job";
import RegulatedEntities from "./components/RegulatedEntities/regulated-entities";
import Careers from "./components/Careers/careers";
import Footer from "./components/SiteElements/footer";
import ContactUs from "./components/Contact/contact";
import About from "./components/About/about";
import BasicPage from "./components/PageTemplates/basic-page";
import Erro404 from "./components/page-not-found";
import { ParallaxProvider } from "react-scroll-parallax";
import JobContext from "./components/context/job-context";

function App() {
  return (
    <Provider store={store}>
      <ParallaxProvider>
        <Router basename="/">
          <JobContext>
            <HeaderNav />
          </JobContext>

          <Routes>
            {window.location.pathname === "/"
              ? (window.location.href = "/home")
              : ""}
            <Route
              path="/home"
              element={
                <JobContext>
                  <Main />
                </JobContext>
              }
            />
            <Route
              path="/careers"
              element={
                <JobContext>
                  <Careers />
                </JobContext>
              }
            />
            <Route path="/careers/positions" element={<JobBoard />} />
            <Route path="/regulated-entities" element={<RegulatedEntities />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about" element={<About />} />
            <Route path="/:id" element={<BasicPage />} />

            <Route
              path="/careers/positions/:id/:id"
              element={
                <div className="content">
                  <JobDetails />
                </div>
              }
            />
            <Route path="/not-found" element={<Erro404 />} />
          </Routes>
          <Footer />
        </Router>
      </ParallaxProvider>
    </Provider>
  );
}

export default App;

import axios from "axios";
import {
  GET_DATA,
  GET_JOB_DATA,
  GET_SINGLE_JOB,
  GET_FOOTER,
  GET_CAREER_DATA,
  GET_REGULATED_DATA,
  GET_CONTACT_DATA,
  GET_ABOUT_DATA,
  GET_HEADER,
  GET_SLUGS,
} from "./types";
//  "proxy": "https://wp.gomarkets.com/group/wp-json/wp/v2/", - in package.json update proxy

const client = axios.create({
  baseURL: "https://wp.gomarkets.com/group/wp-json/wp/v2",
});

const acfClient = axios.create({
  baseURL: "https://wp.gomarkets.com/group/wp-json/acf/v3",
});

export const getData = () => async (dispatch) => {
  await client
    .get(`/homepage/`)
    .then((res) => {
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error.message);
    });
};
export const getPage = (id) => async (dispatch) => {
  await client
    .get(`/pages/?subtype=page&slug=${id}`)
    .then((res) => {
      dispatch({
        type: GET_DATA,
        payload: res.data[0],
      });
    })
    .catch((error) => {
      console.log(error.message);
    });
};
export const getPageSlugs = () => async (dispatch) => {
  await client
    .get(`https://wp.gomarkets.com/group/wp-json/custom/v2/allpages`)
    .then((res) => {
      dispatch({
        type: GET_SLUGS,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error.message);
    });
};
export const getCareers = () => async (dispatch) => {
  await client
    .get(`/careers/`)
    .then((res) => {
      dispatch({
        type: GET_CAREER_DATA,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const getRegulatedEn = () => async (dispatch) => {
  await client
    .get(`/regulated_entities/`)
    .then((res) => {
      dispatch({
        type: GET_REGULATED_DATA,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const getJobData = () => async (dispatch) => {
  await client
    .get(`/job_listing/`)
    .then((res) => {
      dispatch({
        type: GET_JOB_DATA,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const getSingleJob = (id, navigate) => async (dispatch) => {
  await client
    .get(`/job_listing/${id}`)
    .then((res) => {
      dispatch({
        type: GET_SINGLE_JOB,
        payload: res.data,
      });
    })
    .catch((error) => {
      navigate("/not-found");
      console.log(error.message);
    });
};

export const getJobs = () => async (dispatch) => {
  await client
    .get(`/jobs/`)
    .then((res) => {
      dispatch({
        type: GET_DATA,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const getHeader = (id) => async (dispatch) => {
  await axios
    .get(`https://wp.gomarkets.com/group/wp-json/wp-api-menus/v2/menus/${id}`)
    .then((res) => {
      dispatch({
        type: GET_HEADER,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const getFooter = () => async (dispatch) => {
  await acfClient
    .get(`/options/options/`)
    .then((res) => {
      dispatch({
        type: GET_FOOTER,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const getContact = () => async (dispatch) => {
  await client
    .get(`/contact/`)
    .then((res) => {
      dispatch({
        type: GET_CONTACT_DATA,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const getAbout = () => async (dispatch) => {
  await client
    .get(`/about/`)
    .then((res) => {
      dispatch({
        type: GET_ABOUT_DATA,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error.message);
    });
};

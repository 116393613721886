import React, { useEffect, useState } from "react";
import { getPage, getPageSlugs } from "../../actions/mainActions";
import { useDispatch, useSelector } from "react-redux";
import { BannerImage } from "../Utilities/banner-image";
import { useParams, useNavigate } from "react-router-dom";
import BannerSection from "./banner";
import ContentSection from "./basic-panel1";

function BasicPage() {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  let navigate = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    dispatch(getPageSlugs());
  }, [dispatch]);

  let slug = useSelector((mainReducer) => {
    return mainReducer.mainReducer.slugs;
  });

  var render = [];
  let component;
  if (slug && slug.length > 0) {
    var filter = slug.filter((val) => val.slug === id);
    if (filter.length === 0) {
      navigate("/not-found");
    } else {
      if (filter[0].template.includes("top-level")) {
        if (data != null && data.acf) {
          data.acf.section2.map((item) => render.push(item));
        }
        component = <ContentSection data={render} />;
      } else {
        navigate("/not-found");
      }
    }
  }

  useEffect(() => {
    dispatch(getPage(id));
  }, [dispatch, id]);

  let data1 = useSelector((mainReducer) => mainReducer.mainReducer.wpData);

  useEffect(() => {
    if (data1 !== null) {
      setData(data1);
    }
  }, [data1]);

  return (
    <div className="section-div">
      {data != null && data.acf ? (
        <>
          <section
            className="bodyContainer"
            style={BannerImage(data.acf.section1.image.url)}
          >
            <BannerSection data={data.acf} />
          </section>

          <section className="bodyContainer">{component}</section>
        </>
      ) : null}
    </div>
  );
}

export default BasicPage;

import React, { useEffect, useState } from "react";
import HomeSection1 from "./home-banner";
import HomeSection2 from "./home-panel1";
import { getData } from "../../actions/mainActions";
import { useDispatch, useSelector } from "react-redux";
import { BannerImage } from "../Utilities/banner-image";

function Main() {
  const dispatch = useDispatch();
  const [sectionData, setSectionData] = useState();
  const [sectionData2, setSectionData2] = useState();
  const [sectionBox, setSectionBox] = useState();

  useEffect(() => {
    dispatch(getData());
  }, [dispatch]);

  let data1 = useSelector((mainReducer) => mainReducer.mainReducer.wpData);

  useEffect(() => {
    data1.map(
      (data) => (
        setSectionData({
          sectionTitle: data.acf["h-title"],
          sectionText: data.acf["H-DescText1"],
          featImg: data.acf.featured_media.url,
        }),
        setSectionData2({
          vision: data.acf["vision"],
          contactUs: data.acf["contact_us"],
          careers: data.acf["careers_at_go_markets"],
        }),
        setSectionBox({
          aboutGoTitle: data.acf["about_&_reg_ent"]["about_go_markets_-_title"],
          aboutGoDesc: data.acf["about_&_reg_ent"]["about_go_markets_-_desc"],
          moreLink: data.acf["about_&_reg_ent"],
          regEnTitle: data.acf["about_&_reg_ent"]["regulated_entities_-_title"],
          regEnDesc: data.acf["about_&_reg_ent"]["regulated_entities_-_desc"],
        })
      )
    );
  }, [data1]);

  return (
    <>
      {sectionData != null ? (
        <>
          <div
            className="bodyContainer-Home sectionOne"
            style={BannerImage(sectionData.featImg)}
          >
            <HomeSection1
              title={sectionData.sectionTitle}
              desc={sectionData.sectionText}
            />
          </div>

          <div className="bodyContainer sectionTwo">
            <HomeSection2
              vision={sectionData2.vision["vision-title"]}
              visionText={sectionData2.vision["vision-text"]}
              learnMoreBout={sectionBox.moreLink["learn_more-aboutgo"]}
              learnMoreRegEn={sectionBox.moreLink["learn_more-regen"]}
              careerTitle={sectionData2.careers["careers_at_go_markets-title"]}
              careerDesc={sectionData2.careers["careers_at_go_markets-desc"]}
              contactTitle={sectionData2.contactUs["contactus-title"]}
              contactDesc={sectionData2.contactUs["contactus-desc"]}
              contactLink={sectionData2.contactUs["contactus-link"]["url"]}
              contactLinkTxt={sectionData2.contactUs["contactus-link"]["title"]}
              contactImg={sectionData2.contactUs["contactus-image"]}
              aboutGoTitle={sectionBox.aboutGoTitle}
              aboutGoDesc={sectionBox.aboutGoDesc}
              regEnTitle={sectionBox.regEnTitle}
              regEnDesc={sectionBox.regEnDesc}
            />
          </div>
        </>
      ) : null}
    </>
  );
}

export default Main;

import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getJobData } from "../../actions/mainActions";

export const JobContext = createContext();

function JobProvider({ children }) {
  const dispatch = useDispatch();
  const [jobObj, setJobObj] = useState([]);

  useEffect(() => {
    dispatch(getJobData());
  }, [dispatch]);

  let getJob = useSelector((mainReducer) => mainReducer.mainReducer.wpJobData);

  useEffect(() => {
    if (getJob != null) {
      setJobObj(getJob);
    }
  }, [getJob]);

  return <JobContext.Provider value={jobObj}>{children}</JobContext.Provider>;
}

export default JobProvider;

import React from "react";
import { Card, Badge } from "react-bootstrap";

function JobCard(props) {
  // filter out job type
  let jobT;
  for (const value of props.jobType.values()) {
    if (value === 2) {
      jobT = "Full Time";
    } else if (value === 3) {
      jobT = "Part Time";
    } else if (value === 4) {
      jobT = "Temporary";
    } else if (value === 6) {
      jobT = "Internship";
    }
  }

  // check if external job url is in the correct format
  let url = props.jobUrl;
  if (url !== null) {
    if (!url.includes("https://") && url !== "") {
      url = "https://" + props.jobUrl;
    } else {
      url = props.jobUrl;
    }
  }

  return (
    <div className="darkTheme p-2 jobCard">
      <Card className="w-100">
        <Card.Body>
          <Card.Text>
            <small className="text-muted">
              <Badge bg="none" className="card-badge">
                {props.dept}
              </Badge>
            </small>
          </Card.Text>

          <Card.Text className="text-muted">
            <a
              className="title"
              href={`/careers/positions/${props.slug}/${props.id}`}
              dangerouslySetInnerHTML={{
                __html: props.jobTitle,
              }}
            />
          </Card.Text>

          <Card.Text className="text-muted d-flex justify-content-between">
            <small className="extra-info-small">
              {jobT} - {props.location}
            </small>
            <a className="text-decoration-none" href={`/careers/positions/${props.slug}/${props.id}`}>
              Details &gt;
            </a>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
}

export default JobCard;
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form, Card, Container } from "react-bootstrap";
import { getJobData } from "../../actions/mainActions";
import { useDispatch, useSelector } from "react-redux";
import JobCard from "./job-item";

function JobBoard() {
  const [searchData, setSearchData] = useState([]);
  const [searchText, setSearchText] = useState({
    searchLocation: "",
  });

  const dispatch = useDispatch();
  console.log(window.location.search);

  useEffect(() => {
    dispatch(getJobData());
  }, [dispatch]);

  let data1 = useSelector((mainReducer) => mainReducer.mainReducer.wpJobData);

  var filteredLocation = data1.reduce((unique, o) => {
    if (
      !unique.some((obj) => obj.meta._job_location === o.meta._job_location)
    ) {
      unique.push(o);
    }
    return unique;
  }, []);

  // make search inputs case insensitive
  const searchController = (val, item) => {
    return val.toLowerCase().includes(item.toLowerCase());
  };

  // search filter
  const handleSearch = (e) => {
    const searchedItem = e.target.value;
    setSearchText({ ...searchText, [e.target.name]: searchedItem });

    const newFilter = data1.filter((value) => {
      //search by job location
      if (e.target.name === "searchLocation") {
        if (searchController(value.meta._job_location, searchedItem)) {
          return searchController(value.meta._job_location, searchedItem);
        }
      }
    });

    //if the input has value then add that value to searchData[]
    if (searchedItem === "") {
      setSearchData([]);
    } else {
      setSearchData(newFilter);
    }
  };

  useEffect(() => {
    if (searchData.length === 0) {
      setSearchData(data1);
    }
  }, [searchData, data1]);

  const handleClearSearch = (e) => {
    setSearchData([]);

    setSearchText({
      searchLocation: "",
      searchTitle: "",
    });
  };
  const searchInp = (
    <Card className="filter">
      <Form className="p-4">
        <Form.Group
          controlId="exampleForm.ControlSelect1"
          className="addField-animated"
        >
          <Form.Label className="fw-bold">Filter By:</Form.Label>
          <Form.Control
            aria-label="Default select example"
            className="form-select"
            as="select"
            name="searchLocation"
            onChange={handleSearch}
          >
            <option defaultValue="">Choose:</option>
            {filteredLocation.map((items) => (
              <option
                key={items.id}
                value={
                  (searchText.searchLocation = items.meta._job_location || "")
                }
              >
                {items.meta._job_location}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <div className="align-items-center mainBtn">
          <Button
            id="clearBtn"
            name="searchTitle"
            onClick={handleClearSearch}
            className="mb-2 mt-3 w-100"
            disabled={searchText.searchLocation === "" ? true : false}
          >
            Reset
          </Button>
        </div>
      </Form>
    </Card>
  );

  return (
    <div className="content">
      <Container>
        <div className="mt-5">
          <div className="pageSub-Headers content-Center">
            <span>Positions</span>
          </div>
          <div className="pageSub-Nav content-Center">
            <a variant="primary" href="/careers">
              {" "}
              &lt; Back to Careers
            </a>
          </div>
          <hr className="secondary-divider" />
        </div>

        <Row className="row justify-content-center job-searchFilter-Col">
          <Col lg={8}>
            {searchData && searchData.length !== 0
              ? searchData.map((items) => (
                  <Col key={items.id}>
                    <JobCard
                      id={items.id}
                      slug={items.slug}
                      jobTitle={items.title.rendered}
                      jobDesc={items.acf["jobDescription"]}
                      jobQualification={items.acf["jobRequiredQualification"]}
                      jobOtherDetials={items.acf["jobOtherDetails"]}
                      companyName={items._company_name}
                      datePosted={items.date}
                      location={items.meta._job_location}
                      jobType={items["job-types"]}
                      email={items.meta._application}
                      jobUrl={items.acf["jobOtherUrls"]}
                      logoId={items.featured_media}
                      dept={items.acf["jobdepartment"]}
                    />
                  </Col>
                ))
              : "No new job postings..."}
          </Col>
          <Col lg={3}>
            <div className="p-2 row justify-content-center">
              <small>Showing {searchData.length} Job(s)</small>
              <div>{searchInp}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default JobBoard;

import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { getHeader } from "../../actions/mainActions";
import { useDispatch, useSelector } from "react-redux";

function SecondaryNav() {
  const [active, setActive] = useState("group");
  const dispatch = useDispatch();
  var _ = require("lodash");
  let jsonHeaderObj = JSON.parse(localStorage.getItem("subHeaderData"));
  let headerObj = [];

  useEffect(() => {
    dispatch(getHeader(9));
  }, [dispatch]);

  let header = useSelector(
    (mainReducer) => mainReducer.mainReducer.wpSubHeader
  );

  if (header.items != null) {
    for (var i = 0; i < header.items.length; i++) {
      headerObj.push(header.items[i]);
    }

    if (!_.isEqual(jsonHeaderObj, headerObj)) {
      localStorage.setItem("subHeaderData", JSON.stringify(headerObj));
    }
  }

  return (
    <>
      <Navbar bg="light" fixed="top" className="secondaryNav">
        <Container>
          <Nav
            defaultActiveKey={active}
            onSelect={(selectedKey) => setActive(selectedKey)}
          >
            {jsonHeaderObj != null
              ? jsonHeaderObj.map((item, index) => (
                  <Nav.Link
                    key={index}
                    href={item.url}
                    eventKey={item.object_slug}
                    className={active === item.object_slug ? "active" : ""}
                  >
                    {item.title}
                  </Nav.Link>
                ))
              : null}
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default SecondaryNav;

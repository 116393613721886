import {
  GET_DATA,
  GET_JOB_DATA,
  GET_IMG,
  GET_SINGLE_JOB,
  GET_FOOTER,
  GET_CAREER_DATA,
  GET_REGULATED_DATA,
  GET_CONTACT_DATA,
  GET_ABOUT_DATA,
  GET_HEADER,
  GET_SLUGS,
} from "../actions/types";

const initialState = {
  wpData: [],
  careers: [],
  regulatedEn: [],
  contact: [],
  about: [],
  wpJobData: [],
  wpImgData: [],
  wpHeader: [],
  wpSubHeader: [],
  wpFooter: [],
};

export default function setUser(state = initialState, action) {
  switch (action.type) {
    case GET_DATA:
      return {
        ...state,
        wpData: action.payload,
      };
    case GET_SLUGS:
      return {
        ...state,
        slugs: action.payload,
      };
    case GET_CAREER_DATA:
      return {
        ...state,
        careers: action.payload,
      };
    case GET_REGULATED_DATA:
      return {
        ...state,
        regulatedEn: action.payload,
      };
    case GET_CONTACT_DATA:
      return {
        ...state,
        contact: action.payload,
      };
    case GET_ABOUT_DATA:
      return {
        ...state,
        about: action.payload,
      };
    case GET_JOB_DATA:
      return {
        ...state,
        wpJobData: action.payload,
      };
    case GET_IMG:
      return {
        ...state,
        wpImgData: action.payload,
      };
    case GET_SINGLE_JOB:
      return {
        ...state,
        wpJobData: action.payload,
      };
    case GET_HEADER:
      if (action.payload.ID === 8) {
        return {
          ...state,
          wpHeader: action.payload,
        };
      } else {
        return {
          ...state,
          wpSubHeader: action.payload,
        };
      }

    case GET_FOOTER:
      return {
        ...state,
        wpFooter: action.payload,
      };

    default:
      return state;
  }
}

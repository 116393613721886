import React from "react";
import { OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";

function Share() {
  const [copyText, setCopyText] = React.useState("Copy Link");

  const copyPath = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopyText("Page Url Copied");
    setTimeout(() => {
      setCopyText("Copy Link");
    }, 2000);
  };

  return (
    <div className="share">
      {" "}
      <OverlayTrigger
        placement="left"
        overlay={<Tooltip id="button-tooltip-2">{copyText}</Tooltip>}
      >
        {({ ref, ...triggerHandler }) => (
          <div variant="light" {...triggerHandler} className="ms-2">
            <strong
              ref={ref}
              onClick={() => {
                copyPath();
              }}
            >
              <Badge bg="none">
               
                  Share <FontAwesomeIcon icon={faShare} size="1x" />{" "}
               
              </Badge>
            </strong>
          </div>
        )}
      </OverlayTrigger>
    </div>
  );
}

export default Share;

import React, { useEffect, useState } from "react";
import EntitesSection1 from "./entities-banner";
import EntitesSection2 from "./entities-panel1";
import { getRegulatedEn } from "../../actions/mainActions";
import { useDispatch, useSelector } from "react-redux";
import { BannerImage } from "../Utilities/banner-image";

function RegulatedEntities() {
  const dispatch = useDispatch();
  const [sectionData, setSectionData] = useState();

  useEffect(() => {
    dispatch(getRegulatedEn());
  }, [dispatch]);

  let data1 = useSelector((mainReducer) => mainReducer.mainReducer.regulatedEn);

  useEffect(() => {
    data1.map((data) =>
      setSectionData({
        regulatedEntities: data.acf["section1-regulated_entities"],
        entityCards: Object.values(
          data.acf["regulated_entities_info_cards"]
        ),
      })
    );
  }, [data1]);

  return (
    <div className="section-div">
      {sectionData != null ? (
        <>
          <section
            className="bodyContainer"
            style={BannerImage(
              sectionData.regulatedEntities["regulateden-image"].url
            )}
          >
            <EntitesSection1
              title={sectionData.regulatedEntities["regulateden-title"]}
              desc={sectionData.regulatedEntities["regulateden-text"]}
            />
          </section>

          <section className="bodyContainer">
            <EntitesSection2 cards={sectionData.entityCards} />
          </section>
        </>
      ) : null}
    </div>
  );
}

export default RegulatedEntities;
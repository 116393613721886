import React, { useEffect, useState } from "react";
import FooterContent from "./footer-content";
import { getFooter } from "../../actions/mainActions";
import { useDispatch, useSelector } from "react-redux";

function Footer() {
  const dispatch = useDispatch();
  var _ = require("lodash");
  const [footerContent, setFooterContent] = useState();
  let jsonFooterContent = JSON.parse(localStorage.getItem("footerContent"));

  useEffect(() => {
    dispatch(getFooter());
  }, [dispatch]);

  let footer = useSelector(
    (mainReducer) => mainReducer.mainReducer.wpFooter.acf
  );

  useEffect(() => {
    if (footer != null) {
      setFooterContent({
        footerLogo: footer.site_footer_logo.url,
        footerColItems: footer.footer_columns,
      });
    }
  }, [footer]);

  if (footerContent != null) {
    if (!_.isEqual(jsonFooterContent, footerContent)) {
      localStorage.setItem("footerContent", JSON.stringify(footerContent));
    }
  }

  return (
    <>
      {jsonFooterContent != null ? (
        <>
          <FooterContent ftrContent={jsonFooterContent} />
        </>
      ) : null}
    </>
  );
}

export default Footer;

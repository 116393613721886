import React from "react";
import { Row, Container, Col, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

function ContactSection2(props) {
  return (
    <Container>
      <Row>
        {props.location.map((loc, index) =>
          loc[1] !== "" ? (
            <Col
              key={index}
              className="contact-info"
              sm={12}
              md={8}
              lg={4}
              xl={4}
            >
              <Badge>
                <FontAwesomeIcon icon={faMapMarkerAlt} size="1x" />{" "}
                <small>{loc[0]} </small>
              </Badge>

              <h3 className="sectionTitle-Secondary">{loc[1]}</h3>

              <p
                className="sectionContent-Secondary"
                dangerouslySetInnerHTML={{
                  __html: loc[2],
                }}
              />
            </Col>
          ) : (
            ""
          )
        )}
      </Row>
    </Container>
  );
}

export default ContactSection2;

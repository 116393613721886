import React, { useContext, useEffect, useState } from "react";
import CareersSection1 from "./careers-banner";
import CareersSection2 from "./careers-panel1";
import { getCareers } from "../../actions/mainActions";
import { useDispatch, useSelector } from "react-redux";
import { JobContext } from "../context/job-context";

function Careers() {
  const dispatch = useDispatch();
  const [sectionData, setSectionData] = useState();
  const [sectionData2, setSectionData2] = useState();
  const jobData = useContext(JobContext);

  useEffect(() => {
    dispatch(getCareers());
  }, [dispatch]);

  let data1 = useSelector((mainReducer) => mainReducer.mainReducer.careers);

  useEffect(() => {
    data1.map(
      (data) => (
        setSectionData({
          careers: data.acf["section1-careers"],
        }),
        setSectionData2({
          section2: data.acf["section2-careers"],

          businessTable: Object.values(
            data.acf["section2-business_areas_icons1"]
          ),
          businessTable2: Object.values(
            data.acf["section2-business_areas_icons2"]
          ),
        })
      )
    );
  }, [data1]);

  return (
    <div className="careers-main">
      {sectionData != null ? (
        <>
          <div className="bodyContainer careers-sectionOne">
            <CareersSection1
              title={sectionData.careers["careers-title"]}
              desc={sectionData.careers["careers-text"]}
              image={sectionData.careers["careers-image"]}
              jobData={jobData}
            />
          </div>

          <div className="bodyContainer careers-sectionTwo">
            <CareersSection2
              whyGoTitle={sectionData2.section2["why_go_markets"]}
              whyGoDesc={sectionData2.section2["why_go_markets-desc"]}
              whyGoCol1={sectionData2.section2["why_go_markets_pointer_col1"]}
              whyGoCol2={sectionData2.section2["why_go_markets_pointer_col2"]}
              location={sectionData2.section2["locations"]}
              locationDesc={sectionData2.section2["locations-desc"]}
              businessTable={sectionData2.businessTable}
              businessTable2={sectionData2.businessTable2}
              jobData={jobData}
            />
          </div>
        </>
      ) : null}
    </div>
  );
}

export default Careers;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function BannerSection(props) {
  return (
    <Container>
      {props.data ? (
        <Row className="w-100 h-100 lightTheme">
          <Col xs={12} md={8}>
            <Row className="d-flex justify-content-center section-DescText">
              <h1 className="sectionTitle">
                <strong
                  dangerouslySetInnerHTML={{
                    __html: props.data.section1.title,
                  }}
                />
              </h1>
              <div
                className="mt-3 sectionContent"
                dangerouslySetInnerHTML={{
                  __html: props.data.section1.subtitle,
                }}
              />
            </Row>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
}

export default BannerSection;

import React from "react";
import { Row, Col, Container } from "react-bootstrap";

function FooterContent(props) {
  return (
    <>
      <footer className="d-flex justify-content-center align-items-center w-100 main-footer">
        <Container>
          <hr className="divider lightTheme" />
          <Row className="d-flex justify-content-start">
            <Col lg="auto" className="mt-3">
              <img
                alt=""
                src={props.ftrContent.footerLogo}
                width="248.4px"
                height="24px"
                className="d-inline-block align-top"
              />{" "}
            </Col>

            {props.ftrContent &&
              props.ftrContent.footerColItems.map((item, index) => (
                <Col xs="auto" key={index} className="mt-3">
                  <small
                    className="text-muted"
                    dangerouslySetInnerHTML={{
                      __html: item.column_content,
                    }}
                  />
                </Col>
              ))}
          </Row>
          <hr className="divider lightTheme" />
        </Container>
      </footer>
    </>
  );
}

export default FooterContent;

import React from "react";
import { Col, Row, Card, Container } from "react-bootstrap";

function EntitesSection2(props) {
  return (
      <Container>
        <Row>
          {props.cards.map((card, index) => (
            <Col
              key={index}
              className="d-flex justify-content-center"
              sm={12}
              md={8}
              lg={4}
              xl={4}
            >
              {console.log(card)}
              <Card className="sectionContent-Primary text-start regulatedEn-sectionTwo-card">
                <Card.Body
                  dangerouslySetInnerHTML={{
                    __html: card.card,
                  }}
                />
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
  );
}

export default EntitesSection2;
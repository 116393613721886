import React, { useContext } from "react";
import { Col, Row, Card, Button, Container } from "react-bootstrap";
import JobCard from "../Job/job-item";
import { JobContext } from "../context/job-context";

function HomeSection2(props) {
  const jobData = useContext(JobContext);

  return (
    <Container>
      <Row className="w-100 lightTheme mx-0">
        <Col>
          <Row className="d-flex justify-content-center">
            <strong className="content-Headers text-center mb-3">
              {props.vision}
            </strong>
            <h1
              className="sectionContent-Primary"
              dangerouslySetInnerHTML={{ __html: props.visionText }}
            />
          </Row>

          <Row className="mt-5">
            <Col className="sectionTwo-about-entities-box">
              <h1 className="sectionTitle-Secondary">{props.aboutGoTitle}</h1>
              <p
                className="sectionContent-Secondary"
                dangerouslySetInnerHTML={{ __html: props.aboutGoDesc }}
              />
              <a href={props.learnMoreBout.url}>
                {props.learnMoreBout.title} &gt;
              </a>
            </Col>
            <Col className="sectionTwo-about-entities-box">
              <h1 className="sectionTitle-Secondary">{props.regEnTitle}</h1>
              <p
                className="sectionContent-Secondary"
                dangerouslySetInnerHTML={{ __html: props.regEnDesc }}
              />
              <a href={props.learnMoreRegEn.url}>
                {props.learnMoreRegEn.title} &gt;
              </a>
            </Col>
          </Row>
          {jobData.length > 0 ? (
            <Row className="sectionTwo-recentPosts">
              <Col lg={4} className="mt-3">
                <Card className="sectionContent-Primary text-start info-card">
                  <Card.Body>
                    {" "}
                    <strong className="content-Headers">
                      {props.careerTitle}
                    </strong>
                    <h1
                      className="mt-3 sectionContent-Primary"
                      dangerouslySetInnerHTML={{ __html: props.careerDesc }}
                    />
                    <div className="mt-5 mainBtn">
                      <Button href="/careers/positions">See all jobs</Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                {jobData.length > 3
                  ? jobData.slice(0, 3).map((items) => (
                      <Col
                        key={items.id}
                        className="sectionTwo-recentPosts-cards"
                      >
                        <JobCard
                          id={items.id}
                          slug={items.slug}
                          jobTitle={items.title.rendered}
                          jobDesc={items.acf["jobDescription"]}
                          jobQualification={
                            items.acf["jobRequiredQualification"]
                          }
                          jobOtherDetials={items.acf["jobOtherDetails"]}
                          companyName={items._company_name}
                          datePosted={items.date}
                          location={items.meta._job_location}
                          jobType={items["job-types"]}
                          email={items.meta._application}
                          jobUrl={items.acf["jobOtherUrls"]}
                          logoId={items.featured_media}
                          dept={items.acf["jobdepartment"]}
                        />
                      </Col>
                    ))
                  : jobData.map((items) => (
                      <Col
                        key={items.id}
                        className="sectionTwo-recentPosts-cards"
                      >
                        <JobCard
                          id={items.id}
                          slug={items.slug}
                          jobTitle={items.title.rendered}
                          jobDesc={items.acf["jobDescription"]}
                          jobQualification={
                            items.acf["jobRequiredQualification"]
                          }
                          jobOtherDetials={items.acf["jobOtherDetails"]}
                          companyName={items._company_name}
                          datePosted={items.date}
                          location={items.meta._job_location}
                          jobType={items["job-types"]}
                          email={items.meta._application}
                          jobUrl={items.acf["jobOtherUrls"]}
                          logoId={items.featured_media}
                          dept={items.acf["jobdepartment"]}
                        />
                      </Col>
                    ))}
              </Col>
            </Row>
          ) : (
            ""
          )}

          {/*  <div className="mt-5 mainBtn">
              <Button href="/careers/positions">See all jobs</Button>
            </div> */}

          <Card className="sectionTwo-contact">
            <Card.Body>
              <Row className="p-4">
                <Col sm={3} xs={3} className="d-flex justify-content-center">
                  {" "}
                  <img
                    width="64px"
                    height="64px"
                    alt={props.contactImg.alt}
                    src={props.contactImg.url}
                  />
                </Col>
                <Col sm={9} xs={9}>
                  <Card.Text>
                    <strong className="sectionTitle-Secondary">
                      {props.contactTitle}
                    </strong>
                  </Card.Text>

                  <div className="d-flex justify-content-between">
                    <span>
                      <p
                        className="sectionContent-Secondary"
                        dangerouslySetInnerHTML={{
                          __html: props.contactDesc,
                        }}
                      />

                      <a href={props.contactLink}>
                        {props.contactLinkTxt} &gt;
                      </a>
                    </span>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default HomeSection2;

import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function HomeSection1(props) {
  return (
    <>
      <Container>
        <Row className="w-100 h-100 lightTheme">
          <Col xs={12} md={8}>
            <Row className="d-flex sectionOne-Home">
              <h1 className="sectionTitle">
                <strong
                  dangerouslySetInnerHTML={{
                    __html: props.title,
                  }}
                />
              </h1>
              <div className="d-flex mt-3 section-DescText">
                <p
                  className="sectionContent section-DescText"
                  dangerouslySetInnerHTML={{ __html: props.desc }}
                />
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default HomeSection1;

import React from "react";
import { Button, Row, Col } from "react-bootstrap";

function Erro404() {
  const [counter, setCounter] = React.useState(5);

  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  setTimeout(function () {
    window.location.replace("/home");
  }, 5000);

  return (
    <Row className="not-found">
      <Col sm={6}>
        <h1 className="sectionTitle"> Page not found</h1>
      </Col>
      <Col sm={6} className="p-5 ">
        <div className="not-found-content">
          <h3>Sorry, we can't find the page you are looking for.</h3>
          <div className="mt-4">
            <p>You will be redirected to the homepage in {counter} seconds.</p>
            <p>To be redirected immediately, click the button below.</p>
          </div>
          <div className="mt-4 mainBtn">
            <Button href="/home">Return to Homepage</Button>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Erro404;

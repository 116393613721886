export const GET_DATA = "GET_DATA";
export const GET_CAREER_DATA = "GET_CAREER_DATA";
export const GET_REGULATED_DATA = "GET_REGULATED_DATA";
export const GET_CONTACT_DATA = "GET_CONTACT_DATA";
export const GET_ABOUT_DATA = "GET_ABOUT_DATA";
export const GET_JOB_DATA = "GET_JOB_DATA";
export const GET_IMG = "GET_IMG";
export const GET_SINGLE_JOB = "GET_SINGLE_JOB";
export const GET_HEADER = "GET_HEADER";
export const GET_FOOTER = "GET_FOOTER";
export const GET_SLUGS = "GET_SLUGS";

import React from "react";
import { Col, Row, Card, Container } from "react-bootstrap";

function AboutSection2(props) {
  return (
    <Container>
      <Row className="d-flex justify-content-center">
        <div className="mb-5">
          <h1 className="p-4">
            <strong
              className="m-0 sectionTitle"
              dangerouslySetInnerHTML={{
                __html: props.valuesTitle,
              }}
            />
          </h1>

          <h2
            className="sectionContent-Primary d-flex justify-content-center"
            dangerouslySetInnerHTML={{
              __html: props.valuesText,
            }}
          />
        </div>

        {props.cards.map((card, index) => (
          <Col
            key={index}
            className="d-flex justify-content-center"
            sm={12}
            md={8}
            lg={4}
            xl={4}
          >
            <Card className="text-start regulatedEn-sectionTwo-card">
              <Card.Body
                dangerouslySetInnerHTML={{
                  __html: card,
                }}
              />
            </Card>
          </Col>
        ))}
        <div className="mt-5">
          <h1 className="p-4">
            <strong
              className="m-0 sectionTitle"
              dangerouslySetInnerHTML={{
                __html: props.awardsTitle,
              }}
            />
          </h1>

          <h2
            className="sectionContent-Primary d-flex justify-content-center"
            dangerouslySetInnerHTML={{
              __html: props.awardsText,
            }}
          />
          <img
            width="50%"
            height="auto"
            alt={props.awardsImage.alt}
            style={{ marginTop: "54px" }}
            src={props.awardsImage.url}
          />
        </div>
        <div style={{ marginTop: "130px" }}>
          <small className="text-muted sectionContent-Primary">
            {props.investmentTrend}
          </small>
        </div>
      </Row>
    </Container>
  );
}

export default AboutSection2;

import React from "react";
import { Card, Row, Col } from "react-bootstrap";

function Loader() {
  var arr = [];
  for (var i = 0; i < 4; i++) {
    arr.push(i + 1);
  }
  return (
    <>
      {arr.map((i) => (
        <div key={i} className="darkTheme p-2 jobCard containerBg shimmerBG">
          <Card className="w-100" style={{ backgroundColor: "#e8e8e8" }}>
            <Card.Body>
              <div>
                <small className="text-muted">
                  <div className="shimmerBG content-line" />
                </small>
              </div>

              <div className="text-muted">
                <div className="shimmerBG title-line" />
              </div>

              <div className="text-muted d-flex justify-content-between">
                <small className="extra-info-small shimmerBG content-line" />

                <div className="shimmerBG content-line" />
              </div>
            </Card.Body>
          </Card>
        </div>
      ))}
    </>
  );
}

function DetailsLoader() {
  return (
    <>
      <div
        className="mt-5 responsive-center p-2"
        style={{ backgroundColor: "#e8e8e8" }}
      >
        <div className="pageSub-Nav shimmerBG title-line" />

        <hr className="secondary-divider" />
      </div>
      <Row className="row justify-content-center">
        <Col>
          <div style={{ backgroundColor: "#e8e8e8", padding: "4rem" }}>
            <div className="shimmerBG title-line" />

            <div className="mt-4">
              <div className="shimmerBG content-line w-100" />
            </div>
            <div className="mt-4">
              <div className="shimmerBG content-line w-50" />

              <div className="shimmerBG content-line w-75" />
            </div>
            <div className="mt-4">
              <div className="shimmerBG content-line" />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export { Loader, DetailsLoader };

import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

const geoUrl = "https://unpkg.com/world-atlas@2.0.2/countries-110m.json";

const markers = [
  {
    markerOffset: -15,
    name: "Melbourne",
    coordinates: [144.946457, -37.840935],
  },
  { markerOffset: -20, name: "London", coordinates: [-0.118092, 51.509865] },
  { markerOffset: -15, name: "Dubai", coordinates: [55.270782, 25.204849] },
  {
    markerOffset: -10,
    name: "Seychelles",
    coordinates: [55.491978, -4.679574],
  },
  {
    markerOffset: 15,
    name: "Mauritius",
    coordinates: [57.5703566, -20.2759451],
  },
  {
    markerOffset: -15,
    name: "Saint Vincent & the Grenadines",
    coordinates: [-61.2765569, 12.90447],
  },
  {
    markerOffset: -15,
    name: "Limassol",
    coordinates: [33.0332657, 34.6852901],
  },
];

const MapChart = ({ setTooltipContent }) => {
  return (
    <div data-tip="">
      <ComposableMap
        projectionConfig={{
          rotate: [-40, -10, -20],
        }}
        height={500}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill="#DDD"
                stroke="#FFF"
                onMouseEnter={() => {
                  const NAME = geo.properties.name;
                  setTooltipContent(NAME);
                }}
                onMouseLeave={() => {
                  setTooltipContent("");
                }}
                style={{
                  default: {
                    fill: "#D6D6DA",
                    outline: "none",
                    transition: "0.4s",
                  },
                  hover: {
                    fill: "#0bab47",
                    outline: "none",
                  },
                  pressed: {
                    fill: "#0bab47",
                    outline: "none",
                  },
                }}
              />
            ))
          }
        </Geographies>
        {markers.map(({ name, coordinates, markerOffset }) => (
          <Marker key={name} coordinates={coordinates}>
            <circle r={4} fill="#0bab47" stroke="#fff" strokeWidth={2} />
            <text
              textAnchor="middle"
              y={markerOffset}
              style={{
                fontFamily: "system-ui",
                fill: "#5D5A6D",
                fontSize: "10px",
                fontWeight: "bold",
              }}
            >
              {name}
            </text>
          </Marker>
        ))}
      </ComposableMap>
    </div>
  );
};

export default MapChart;

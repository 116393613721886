import React from "react";
import { Col, Row, Button, Container } from "react-bootstrap";
import { ParallaxBanner, Parallax } from "react-scroll-parallax";

function CareersSection1(props) {
  return (
    <Container>
      <Row className="w-100 h-100 lightTheme">
        <Col xs={12} md={6} className="my-5">
          <Row className="d-flex justify-content-center">
            <h1 className="mb-3 pageSub-Headers m-0">{props.title}</h1>
            <p className="sectionContent">{props.desc}</p>
            {props.jobData.length > 0 ? (
              <div className="mainBtn">
                <Button href="/careers/positions">See more jobs</Button>
              </div>
            ) : (
              ""
            )}
          </Row>
        </Col>

        <Col className="careers-image p-0">
          <Parallax translateY={[-20, 20]}>
            <ParallaxBanner layers={[{ speed: -15 }]} className="aspect-[2/1]">
              {" "}
              <svg viewBox="0 0 75 75" width="100%">
                <defs>
                  <pattern id="imgpattern" x="0" y="0" width="1" height="1">
                    <image
                      href={props.image.url}
                      alt={props.image.alt}
                      preserveAspectRatio="xMinYMin slice"
                      x="0"
                      y="0"
                      width="100%"
                      height="100%"
                    />
                  </pattern>
                </defs>
                <path
                  d="m5,18 18,-18 28,0 18,18 0,28 -18,18, -28,0 -18,-18z"
                  strokeWidth="0.5"
                  fill="url(#imgpattern)"
                />
              </svg>
            </ParallaxBanner>
          </Parallax>
        </Col>
      </Row>
    </Container>
  );
}

export default CareersSection1;

import React, { useState, useEffect, useContext } from "react";
import { Navbar, Container, Nav, Button } from "react-bootstrap";
import SecondaryNav from "../SiteElements/secondary-nav";
import { getFooter, getHeader } from "../../actions/mainActions";
import { useDispatch, useSelector } from "react-redux";
import { JobContext } from "../context/job-context";

function HeaderNav() {
  const dispatch = useDispatch();
  const jobData = useContext(JobContext);

  var _ = require("lodash");
  const [active, setActive] = useState(window.location.pathname);
  const [showHeader, setShowHeader] = useState(false);
  let jsonHeaderObj = JSON.parse(localStorage.getItem("headerData"));
  let headerObj = [];

  useEffect(() => {
    dispatch(getFooter());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getHeader(8));
  }, [dispatch]);

  const handleScroll = () => {
    setShowHeader(
      document.body.getBoundingClientRect().y < 30 &&
        document.body.getBoundingClientRect().y !== 0
    );
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  let header = useSelector((mainReducer) => mainReducer.mainReducer.wpHeader);

  if (header.items != null) {
    for (var i = 0; i < header.items.length; i++) {
      headerObj.push(header.items[i]);
    }

    if (!_.isEqual(jsonHeaderObj, headerObj)) {
      localStorage.setItem("headerData", JSON.stringify(headerObj));
    }
  }

  let footer = useSelector(
    (mainReducer) => mainReducer.mainReducer.wpFooter.acf
  );

  if (footer != null) {
    if (localStorage.headerLogo !== footer.site_logo.url) {
      localStorage.setItem("headerLogo", footer.site_logo.url);
    }
  }

  const logoImg =
    footer != null ? (
      <img
        alt=""
        src={footer.site_logo.url}
        width="182px"
        height="auto"
        className="d-inline-block align-top nav-sitelogo"
      />
    ) : (
      <img
        alt=""
        src={localStorage.headerLogo}
        width="182px"
        height="auto"
        className="d-inline-block align-top nav-sitelogo"
      />
    );

  return (
    <>
      <SecondaryNav />
      <Navbar
        collapseOnSelect
        expand="xl"
        variant="dark"
        fixed="top"
        id={showHeader ? "siteNav" : ""}
        className={
          window.location.pathname === "/home" ? "main-nav box" : "main-nav"
        }
      >
        <Container>
          <Navbar.Brand href="/" className="m-0">
            {logoImg}
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text
              className={!active.includes("/careers") ? "navtextContainer" : ""}
            >
              <Nav
                className="me-aut"
                defaultActiveKey={active}
                onSelect={(selectedKey) => setActive(selectedKey)}
              >
                {jsonHeaderObj != null
                  ? jsonHeaderObj.map((item, index) => (
                      <Nav.Link
                        key={index}
                        href={`/${item.object_slug}`}
                        eventKey={item.object_slug}
                        className={
                          active.includes(item.object_slug) ? "active" : ""
                        }
                      >
                        {item.title}
                      </Nav.Link>
                    ))
                  : null}
              </Nav>
            </Navbar.Text>
            {active.includes("/careers") && jobData.length > 0 ? (
              <div className="mainBtn" id="careersBtn">
                <Button href="/careers/positions">See all jobs</Button>
              </div>
            ) : (
              ""
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default HeaderNav;
